<script>
  import LoadingSpinner from '@cox2m/city-services-ui-components/src/components/LoadingSpinner.svelte';
  import MuiTextInput from '@cox2m/city-services-ui-components/src/forms/MuiTextInput.svelte';
  import MainScreen from '@cox2m/city-services-ui-components/src/main/MainScreen.svelte';
  import Button from '@cox2m/city-services-ui-components/src/components/Button.svelte';
  import Modal from '@cox2m/city-services-ui-components/src/components/Modal.svelte';

  import {setSuccessToast} from '@cox2m/city-services-ui-components/src/funcs';
  import {menuOpts, user} from 'web-stores';
  import {getSettings, updateSetting} from '../actions';
  import {onMount} from 'svelte';

  let fetchSettingsError = false;
  let fetchLoading = true;

  const REQUIRED_KEYS = ['sessionTimeout', 'idleTime'];
  let writtableSettings = [];
  let readableSettings = [];

  const saveOrganizationSettings = async () => {
    let writtableSettingsToUpdate = writtableSettings.filter(settingObject => settingObject.dirty);
    fetchLoading = true;

    await Promise.all(
      writtableSettingsToUpdate.map(async ({name, value}) => {
        const updateSettingResponse = await updateSetting($user.token, {name, value});
        if(updateSettingResponse.fulfilled){
          setSuccessToast(window.dispatchEvent, `${name} setting Saved`);
        }
      })
    ).then(() => {
      writtableSettings.map(settingObject => settingObject.dirty = false)
      fetchLoading = false
    })
  };

  onMount(async () => {
    const settingsResponse = await getSettings($user.token);

    if (settingsResponse.fulfilled) {
      settingsResponse.settings.forEach(settingObject => {
        if (REQUIRED_KEYS.includes(settingObject.name)) {
          writtableSettings = [
            ...writtableSettings,
            {
              ...settingObject,
              //this could change depending on what key the want to modify
              value: settingObject.value || 0,
              isOnError: false,
              dirty: false
            }
          ];
        } else {
          readableSettings = [
            ...readableSettings,
            {
              ...settingObject,
              //this could change depending on what key the want to modify
              value: settingObject.value || 0
            }
          ];
        }
      });
    } else {
      fetchSettingsError = true;
    }

    fetchLoading = false;
  });
</script>

<style>
  .dashboard-component-container {
    background-color: var(--cox2m-clr-neutral-white);
    border-radius: 16px;
    padding: var(--cox2m-spacing-8-units);
    min-height: 100%;
    box-shadow: var(--cox2m-shadow-3-dp-dir) var(--cox2m-shadow-3-dp-clr);
    overflow-y: auto;
  }
  .place-center {
    height: -webkit-fill-available;
    display: grid;
    place-content: center;
  }
  .organization-settings-content-container {
    display: grid;
    grid-template-rows: 1fr auto;
    height: -webkit-fill-available;
  }
  .confirmation-section {
    display: flex;
    align-items: center;
    justify-content: end;
  }
  .organization-setting-selector {
    margin-bottom: var(--cox2m-spacing-8-units);
  }
  :global(.mui-text-input label) {
    width: calc(100% - var(--cox2m-spacing-4-units));
  }
  .view-title {
    font-weight: var(--cox2m-fw-bold);
    font-size: var(--cox2m-fs-400);
    color: var(--cox2m-clr-neutral-black);
    margin-bottom: var(--cox2m-spacing-8-units);
  }

  @media only screen and (max-width: 767px) {
    .dashboard-component-container {
      padding: var(--cox2m-spacing-4-units);
    }
  }
</style>

<Modal>
  <MainScreen title="Logs" menuOpt={$menuOpts} user={$user}>
    <div slot="main-dashboard" class="dashboard-component-container">
      {#if fetchLoading}
        <div class="place-center">
          <LoadingSpinner />
        </div>
      {:else if fetchSettingsError}
        <div class="place-center">
          <h2>
            We are sorry we are getting an error fetching the organization
            settings, please refresh the page or try again later
          </h2>
        </div>
      {:else if !fetchSettingsError}
        <div class="organization-settings-content-container">
          <section class="settings-section" data-testid="settings-section">
            <h1 data-testid="view-title" class="view-title">Organization Settings</h1>
            {#each readableSettings as organizationSetting, index}
              <div class="organization-setting-selector">
                <MuiTextInput
                  customContainerClass="mui-text-input w-100"
                  id="{organizationSetting.name}-input"
                  name={organizationSetting.name}
                  label={organizationSetting.name}
                  padding="var(--cox2m-spacing-3-units)"
                  value={readableSettings[index].value}
                  disabled={true} />
              </div>
            {/each}

            {#each writtableSettings as organizationSetting, index}
              <div class="organization-setting-selector">
                <MuiTextInput
                  customContainerClass="mui-text-input w-100"
                  id="{organizationSetting.name}-input"
                  name={organizationSetting.name}
                  label={organizationSetting.name + ' (minutes)'}
                  padding="var(--cox2m-spacing-3-units)"
                  bind:value={writtableSettings[index].value}
                  disabled={false}
                  validationFunction={() => !isNaN(writtableSettings[index].value)}
                  onChange={() => {
                    writtableSettings[index].dirty = true;
                  }}
                  on:validationSuccess={() => {
                    writtableSettings[index].isOnError = false;
                  }}
                  on:validationFailure={() => {
                    writtableSettings[index].isOnError = true;
                  }}
                  inputError={writtableSettings[index].isOnError} />
              </div>
            {/each}
          </section>

          <section class="confirmation-section" data-testid="confirmation-section">
            <Button
              customClass="font-weight-bold"
              id="save-button"
              text="Save Changes"
              width="fit-content"
              height="var(--cox2m-spacing-10-units)"
              backgroundColor="var(--cox2m-clr-brand-600)"
              color="var(--cox2m-clr-neutral-white)"
              margin="0 0 var(--cox2m-spacing-3-units) var(--cox2m-spacing-7-units)"
              padding="0 var(--cox2m-spacing-4-units)"
              disabled={writtableSettings.some(settingsObject => settingsObject.isOnError) || writtableSettings.every(settingObject => !settingObject.dirty)}
              on:click={() => saveOrganizationSettings()} />
          </section>
        </div>
      {/if}
    </div>
  </MainScreen>
</Modal>
