<script>
  import Icon from '@cox2m/city-services-ui-components/src/components/Icon.svelte';
  export let props = {};
</script>

<style>
  .document-icon-container{
    margin-left: var(--cox2m-spacing-2-units);
  }
</style>

<div class="d-flex align-items-center document-icon-container">
  <Icon icon="file-text" color="var(--cox2m-clr-critical-500)" size="var(--cox2m-spacing-9-units)" className="pr-3 flex-shrink-0" />
  <p class="m-0">
    <strong>{props.name}</strong>
  </p>
</div>
