<script>
  import MainScreen from '@cox2m/city-services-ui-components/src/main/MainScreen.svelte';
  import Modal from '@cox2m/city-services-ui-components/src/components/Modal.svelte';
  import DocumentsForm from 'web-forms/documents/DocumentsForm.svelte';

  import {menuOpts, user} from 'web-stores';
</script>

<style>
  /* .container {
    height: 100%;
    display: grid;
    grid-template-rows: var(--cox2m-spacing-15-units) calc(100% - var(--cox2m-spacing-15-units));
  }

  @media (max-width: 767.98px) {
    .container {
      grid-template-rows: var(--cox2m-spacing-21-units) calc(100% - var(--cox2m-spacing-21-units));
      max-width: none;
      padding: 0px;
      margin: 0px;
    }
  } */
</style>

<Modal>
  <MainScreen title="Documents" menuOpt={$menuOpts} user={$user}>
    <div slot="main-dashboard" class="w-100 h-100">
      <DocumentsForm />
    </div>
  </MainScreen>
</Modal>
