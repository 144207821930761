<script>
  import Icon from '@cox2m/city-services-ui-components/src/components/Icon.svelte';
  import LoadingSpinner from '@cox2m/city-services-ui-components/src/components/LoadingSpinner.svelte';
  import {downloadDocumentById} from 'web-actions';
  export let props = {};

  let loading = false;

  const handleDownload = async () => {
    loading = true;
    const downloadResponse = await downloadDocumentById(props.id);
    loading = false;

    const a = document.createElement('a');
    a.href = 'data:application/octet-stream;base64,' + downloadResponse;
    a.download = props.name;
    a.click();
  };
</script>

<div class="d-flex justify-content-end" style="padding-right: 6px;">
  {#if !loading}
    <Icon icon="file-download" id="{props.id}-download-icon" color="var(--cox2m-clr-brand-500)" size="var(--cox2m-spacing-6-units)" onClick={handleDownload} />
  {:else}
    <div
      class="d-flex align-items-center justify-content-center"
      style="width: calc(var(--cox2m-spacing-13-units) - var(--cox2m-visual-corrector)); height: var(--cox2m-spacing-10-units);">
      <LoadingSpinner />
    </div>
  {/if}
</div>
