<script>
  export let handleFileLoad = () => {};

  let fileName = 'Choose file';
  const handleOnLoad = async () => {
    const file = document.getElementById('file-loader-input').files[0];
    fileName = file.name;
    handleFileLoad(file);
  };
</script>

<style>
  .border {
    border: var(--cox2m-brd-w-3) solid var(--cox2m-clr-neutral-400) !important;
  }
</style>

<form id="file-loader-input-container" class="p-3">
  <div class="custom-file">
    <input
      type="file"
      class="custom-file-input border"
      id="file-loader-input"
      accept=".pdf"
      on:change={handleOnLoad} />
    <label class="custom-file-label" for="file-loader-input">{fileName}</label>
  </div>
</form>
