<script>
  import TextInput from '@cox2m/city-services-ui-components/src/forms/TextInput.svelte';
  import Button from '@cox2m/city-services-ui-components/src/components/Button.svelte';
  import Table from '@cox2m/city-services-ui-components/src/components/Table.svelte';
  import Modal from '@cox2m/city-services-ui-components/src/components/Modal.svelte';
  import DownloadFileRow from './components/DownloadFileRow.svelte';
  import NameFileRow from './components/NameFileRow.svelte';
  import AddDocumentModal from './AddDocumentModal.svelte';

  import {getDocumentsList} from 'web-actions';
  import {openModalComponent} from 'web-funcs';
  import {user} from 'web-stores';
  import {onMount} from 'svelte';
  import SelectList from '@cox2m/city-services-ui-components/src/components/SelectList.svelte';

  const ORDER_BY_LIST_OPTIONS = [
    {label: 'Most recent', value: 'most_recent'},
    {label: 'Oldest', value: 'oldest'}
  ];

  let orderByValue = 'most_recent';
  let documentToSearch = '';
  let onRowClick = () => {};

  let columns = [
    {label: 'Name', key: 'name', type: 'text', Component: NameFileRow},
    {label: 'Type', key: 'type', type: 'number'},
    {label: 'Group', key: 'group', type: 'text'},
    {key: 'download', type: 'object', Component: DownloadFileRow, width: 50}
  ];
  let rows = [];

  const refreshDocumentList = async () => {
    rows = [];
    const getDocumentsListResponse = await getDocumentsList({
      token: $user.token,
      query: '',
      limit: '20'
    });
    if (getDocumentsListResponse.fulfilled) {
      getDocumentsListResponse.collection.forEach(item => {
        rows.push({
          name: item.fileName,
          type: item.extension,
          createdAt: item.createdAt,
          group: 'Engineering',
          id: item.id
        });
      });
      rows = [...rows];
    }
  };

  onMount(() => {
    refreshDocumentList();
  });

  const openAddDocumentModal = () => {
    openModalComponent(AddDocumentModal, {
      refreshDocumentList: refreshDocumentList
    });
  };

  $: tableRows = [
    ...rows
      .filter(
        row =>
          documentToSearch === '' ||
          row.name.toLowerCase().includes(documentToSearch.toLowerCase())
      )
      .sort((a, b) =>
        orderByValue === 'most_recent'
          ? new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          : new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      )
  ];
</script>

<style>
  .documents-form-container {
    background-color: var(--cox2m-clr-neutral-white);
    padding: var(--cox2m-spacing-4-units);
    border-radius: 1rem;
  }
  .filters-container{
    gap: var(--cox2m-spacing-4-units);
    align-items: center;
    justify-content: space-between;
    display: flex;
  }

  .table-container{
    margin-top: var(--cox2m-spacing-10-units);
  }

  @media only screen and (max-width: 767px){
    .filters-container{
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-start;
    }
    :global(#documents-dashboard-order-by-container, #documents-dashboard-order-by){
      width: 100%;
    }
  }
</style>

<Modal>
  <div class="documents-form-container cox2m-shadow-1-dp">
    <div class="filters-container">
      <TextInput
        bind:value={documentToSearch}
        id="documents-dashboard-search-text-field"
        name="user-search"
        icon="search"
        placeholder="Search"
        customContainerClass="border bg-white" />

      <SelectList
        minWidth="120px"
        defaultLabel="Most Recent"
        borderColor="var(--cox2m-clr-brand-400)"
        iconColor="var(--cox2m-clr-brand-600)"
        options={ORDER_BY_LIST_OPTIONS}
        height="var(--cox2m-spacing-12-units)"
        id="documents-dashboard-order-by"
        on:selectedOptionChange={e => (orderByValue = e.detail.value)} />

      <Button
        maxWidth="215px"
        customClass="cox2m-shadow-1-dp d-flex align-items-center
        justify-content-center w-100 m-0"
        text="New Document"
        backgroundColor="var(--cox2m-clr-brand-600)"
        id="documents-dashboard-add-button"
        height='var(--cox2m-spacing-12-units)'
        on:click={openAddDocumentModal}
        icon="plus"
        iconColor="var(--cox2m-clr-neutral-white)"
        iconSize="var(--cox2m-spacing-7-units)">
        <span class="ml-1">New Document</span>
      </Button>
    </div>

    <div class="d-flex table-container">
      <Table
        id="documents-dashboard-table"
        {columns}
        rows={tableRows}
        {onRowClick} />
    </div>
  </div>
</Modal>
